<template>
  <div >
    <v-container> 
    <TitleComponent :title="'List of Ongoing Projects'"/>
    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10"
        class="elevation-1 spacing-playground pt-10"
        dense
        
    >
    <template v-slot:item.Abc="{ item }">
      {{ formatData(item.Abc) }}
    </template>
    </v-data-table>   
   
    </v-container>

    <v-container> 
    <TitleComponent  :title="'Ongoing Projects Billboards'"/>
     <v-card
    elevation="0"
    outlined
    rounded
     :max-height="$vuetify.breakpoint.mdAndUp ? 300 : 230"
    > 
        <swiper ref="mySwiper"  class="swiper" :options="swiperOptions">

          <swiper-slide
            v-for="(item, i) in this.projectbillboards"   
            :key="i"
          >
           
            <enlargeable-image :src="$api_url+item.Url" :src_large="$api_url+item.Url" /> 
         
          </swiper-slide>
          
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </v-card>  
    </v-container> 

    <Footer v-if="this.$route.name !== 'Home'"> </Footer>
    
  </div>
</template>

<script>

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import axios from 'axios' 
import TitleComponent from '@/components/TitleComponent'
import Footer from '@/components/Footer'
import EnlargeableImage from '@diracleo/vue-enlargeable-image';

import 'swiper/css/swiper.css'

export default {
  name : 'OngoingProjects',
  components: {
    TitleComponent,
    Footer,
    Swiper,
    SwiperSlide,
    EnlargeableImage
  },
  directives: {
    swiper: directive
  },
  data () {
    return {
      items:[],
       swiperOptions: {
          slidesPerView: this.$vuetify.breakpoint.mdAndUp ? 3 : 1,
          spaceBetween: 10,
          centeredSlides: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
          // Some Swiper option/callback...
        },
      projectbillboards:[],
      headers: [
        {   
          text: 'Program', 
          value: 'Program', 
        },
        {   
          text: 'FUNDING YEAR', 
          value: 'FundingYear', 
          align: 'center'
        },
        {   
          text: 'PROJECT NAME', 
          value: 'Name', 
          align: 'center'
        },
        {   
          text: 'Location', 
          value: 'Location', 
          align: 'center'
        },
        {   
          text: 'ALLOCATED BUDGET', 
          value: 'Abc', 
          align: 'center'
        },
        {   
          text: 'Status', 
          value: 'Status', 
          align: 'center'
        },

      ],
    }

    },

    mounted(){
      //alert(this.items);
      this.items=this.getAllRecords(`/getAllRecords/projects/where STATUS='ONGOING'/ID asc`);
      this.projectbillboards=this.getAllRecords(`/getAllRecords/projectbillboards/where STATUS='ONGOING'/ID asc`);
      //alert(THIS.projectbillboards);

    },  

    updated(){

     
    },

    methods:{
      formatData(value){
        //alert(value);!isNaN(Date.parse(value)) 
        //alert(value)
        var findchar=value.toString().search('-');
        if ( !isNaN(parseFloat(value)) && (findchar < 0)) { 
            
            return Number(value).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2});
        }
        else if( !isNaN(Date.parse(new Date(value))) ){
            
            return this.formatDate(value);
        }
        else{
            return value
        }
      },
      getAllRecords:function(query){
      axios.get( this.$api_url+query,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              this.items=response.data;
              this.projectbillboards=response.data;
              //return this.items
            }
      })
      .catch(error => { console.log(error)})
    },  
  }
}

</script>

<style lang="scss">

</style>